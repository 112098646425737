import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Containers/ContentContainer"

const HeaderBlockContainer = styled.div`
  display:none;
  margin-top: 87px;
  text-align: center;
  background-color:${colors.purple};
  padding-top:40px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 100px;
  }
`

const HeaderContents = styled.div`
  max-width: 455px;
  margin: auto;
`

const Header = styled.h2`
  ${fonts.garamondBold};
  font-size: 32px;
  line-height: 1.1;

  @media (min-width: ${breakpoints.md}) {
    font-size: 32px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 32px;
  }
`

const Content = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.33;
`

const HeaderBlock = (props) => {
  const { title, content } = props

  return (
    <HeaderBlockContainer>
      <ContentContainer>
        <HeaderContents>
          <Header>{title}</Header>
          {/* {content && <Content>{renderRichText(content)}</Content>} */}
        </HeaderContents>
      </ContentContainer>
    </HeaderBlockContainer>
  )
}

export default HeaderBlock
