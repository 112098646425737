import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import colors from "../../styles/colors"


const FeaturesContainer = styled.div`
  display:none;
  padding: 20px;
  background-color:${colors.purple};
  margin-top:-1px;
`

const Features = styled.div`
  padding: 20px 0;
`

const StyledRow = styled(Row)`
  max-width: 320px;
  margin: auto;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 800px;
    gap:15px;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 1000px;
    gap:0;
  }
`

const Feature = styled.div`
  font-size: 14px;
  line-height: 1.33;
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 0 10px;
  max-width: 160px;
  height: 80px;
  font-weight:900;
  flex-direction: column;
  text-align: center;
  div {
    margin-left: 10px;
  }
  img {
    height: 48px;
  }

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 200px;
    height: auto;
    margin: 10px auto;
    text-align: center;
    div {
      margin-left: 0;
      min-width: 100%;
      margin-top: 12px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 20px auto;
  }
`

const FeaturesBlock = (props) => {
  const { features } = props
  return (
    <FeaturesContainer>
        <Features>
          <StyledRow justifyContent={"center"}>
            {features.map((feature, index) => {
              const { icon, label } = feature
              return (
                <Col xs={6} sm={4} md={3} lg={2.4} key={index}>
                  <Feature>
                    <img src={icon.file.url} alt={label || ""} aria-hidden={true}/>
                    <div>{label}</div>
                  </Feature>
                </Col>
              )
            })}
          </StyledRow>
        </Features>
    </FeaturesContainer>
  )
}

export default FeaturesBlock
