const getIndexSchema = (products) => {
  let schema =  {
    "@context": "https://schema.org",
    "@type": "Store",
    "name": "Jupiter",
    "url": "https://hellojupiter.com/",
    "logo": "https://scontent-msp1-1.xx.fbcdn.net/v/t1.6435-9/127172883_10158383705173183_9222842737493410666_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=oW0514w6h5AAX-p9CN-&_nc_ht=scontent-msp1-1.xx&oh=00_AfBlAXUZBx0qIsoZLdXlbZkwasBvl5b2Sz_tMpPuYxWi-A&oe=63F7933F",
    "description": "Jupiter is an ecommerce brand that offers a wide range of anti-dandruff and scalp health products. We understand the struggles of dealing with dandruff and that's why we strive to deliver high-quality, science-backed products in a judgment-free environment. With dermatologist-endorsed guidance and an amazing scalp care experience, we hope to empower our customers to confidently take control of their scalp health and say goodbye to flakes.",
    "email": "support@hellojupiter.com",
    "sameAs": [
      "https://www.facebook.com/jupiter/",
      "https://mobile.twitter.com/zeroflakesgiven",
      "https://www.instagram.com/hellojupiter/?hl=en",
      "https://www.linkedin.com/company/jupiterthebrand",
      ""
    ],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Anti Dandruff Products",
      "itemListElement": []
    }

  }

  products.forEach(product => {
    const {title, imageSrc, description, price, inStock, slug, rating, reviewCount} = product
    schema.hasOfferCatalog.itemListElement.push({
      "@type": "Offer",
      "itemOffered": {
        "@type": "Product",
        "name": title.includes("Scalp Brush") ? "Scalp Brush" : title,
        "image": imageSrc,
        "description": description,
        "offers": {
          "@type": "Offer",
          "price": price,
          "priceCurrency": "USD",
          "availability": inStock ? "https://schema.org/InStock" : "https://schema.org/PreOrder",
          "url": `https://hellojupiter.com/products/${slug}`
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": rating,
          "reviewCount": reviewCount
        },
        "brand": {
          "@type": "Brand",
          "name": "Jupiter"
        }
      }
    })
  })

  schema.hasOfferCatalog.itemListElement.forEach(product => {
    if (product.itemOffered.aggregateRating.ratingValue === 0 || product.itemOffered.aggregateRating.reviewCount === 0) {
      delete product.aggregateRating
    }
  })

  return schema
}

const getPdpSchema = (product) => {
  const {title, imageSrcs, description, price, inStock, slug, rating, reviewCount} = product
  let schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": title,
    "image": imageSrcs,
    "description": description,
    "brand": {
      "@type": "Brand",
      "name": "Jupiter"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": rating,
      "reviewCount": reviewCount
    },
    "offers": {
      "@type": "Offer",
      "price": price,
      "priceCurrency": "USD",
      "availability": inStock ? "https://schema.org/InStock" : "https://schema.org/PreOrder",
      "url": `https://hellojupiter.com/products/${slug}`
    }
  }

  if (rating === 0 || reviewCount === 0) {
    delete schema.aggregateRating
  }

  return schema
}

export {
  getIndexSchema,
  getPdpSchema
}